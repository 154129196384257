import * as cc from '@consignor/common-components';
import { Logger, LogLevel } from '@validide/logger';
import * as Oidc from 'oidc-client-ts';
import { ServerHttpResources } from './../constants';
import { LogAdapter } from './logAdapter';

const GLOBAL_OIDC_SETTINGS: Partial<Oidc.UserManagerSettings> = {
  response_type: 'code',
  response_mode: 'query',
  filterProtocolClaims: true,
  loadUserInfo: true,
  automaticSilentRenew: true,
  validateSubOnSilentRenew: true,
  revokeTokensOnSignout: true,
  monitorSession: true
};

const getJsonIfSuccess = <T>(request: XMLHttpRequest, logger: Logger): (T | null) => {
  try {
    return (request.status >= 200 && request.status < 300)
      ? JSON.parse(request.responseText)
      : null;
  } catch (error: any) {
    logger.log(LogLevel.Error, 'Failed to parse JSON response.', error, {
      status: request.status,
      response: request.responseText,
      url: request.responseURL
    });
  }
  return null;
};

export class UserManager implements cc.Core.IUserManager {
  private static isLoggerSet = false;
  private static stateStore: Oidc.WebStorageStateStore | null = null;
  protected static defaultManager: Oidc.UserManager | null = null;
  private readonly _host: cc.Host.IHostApplication;
  private readonly _logger: Logger;
  private _appSettings: Record<string, string> = {};
  private _userPromise: Promise<cc.Core.IUser | null> | null;
  private _userContext: any;
  private _signOutCallback: any;
  private _unloadCallback: any;
  private _stateChangedCallback: any;

  protected readonly _window: Window;
  protected readonly _url: string;
  protected readonly _authority?: string;
  protected _manager?: Oidc.UserManager;
  protected handlerDelay: number = 5_000;
  private readonly _userStateChangedHandlers: cc.Core.UserSessionChangedHandler[] = [];

  constructor(window: Window, url: string, host: cc.Host.IHostApplication, authority?: string) {
    this._window = window;
    this._url = url;
    this._host = host;
    this._authority = authority;
    this._logger = host.loggerFactory.getLogger('common-components-auth');
    this._userPromise = null;
    this._userContext = null;
    UserManager.setLogger(new LogAdapter(this._logger));
  }

  /**
   * @inheritdoc
   */
  public async initialize(): Promise<void> {
    const settingsResponse = await cc.Utilities.sendAsync({ url: this._url });
    const settings = getJsonIfSuccess<any>(settingsResponse.request, this._logger);

    if (typeof settings !== 'object' || !settings) {
      throw new Error(`Failed to retrieve settings OIDC from "${this._url}"`);
    }

    const appRootUrl = this._host.configuration[cc.Host.ConfigurationKeys.appRootPathAbsolute] ?
      (this._host.configuration[cc.Host.ConfigurationKeys.appRootPathAbsolute] as string).replace(/\/$/g, '') :
      '';

    Object.keys(settings).forEach(key => {
      if (typeof settings[key] === 'string') {
        settings[key] = settings[key].replace('{APP_ROOT_PATH_ABSOLUTE}', appRootUrl);
      }
    });

    this._appSettings = settings;

    this._manager = this.buildOidcManager({
      ...settings,
      ...GLOBAL_OIDC_SETTINGS,
      authority: this._authority || this._appSettings.authority,
      stateStore: UserManager.GetStateStore(this._window),
      userStore: UserManager.GetStateStore(this._window)
    });

    this._signOutCallback = async () => {
      this._userContext = null;
      await UserManager.clearUserMangerData(this._manager);
      this.callUserStateChangedHandlers(true);
    };

    this._unloadCallback = async () => {
      this._userContext = null;
      await UserManager.clearUserMangerData(this._manager);
      this.callUserStateChangedHandlers(false);
    };

    this._stateChangedCallback = () => {
      this.callUserStateChangedHandlers(false);
    };

    this._manager.events.addUserLoaded(this._stateChangedCallback);
    this._manager.events.addUserSignedIn(this._stateChangedCallback);
    this._manager.events.addUserSessionChanged(this._stateChangedCallback);
    this._manager.events.addUserSignedOut(this._signOutCallback);
    this._manager.events.addUserUnloaded(this._unloadCallback);
    this._manager.events.addSilentRenewError(this._signOutCallback);

    await this._manager.clearStaleState();
  }

  /**
   * @inheritdoc
   */
  public async signIn(): Promise<void> {
    const manager = this.getManager();
    await manager.clearStaleState();

    const stateData = this.getStateData();
    if (this.usePopUps()) {
      await manager.signinPopup(stateData);
    }
    else {
      await manager.signinRedirect(stateData);
    }
  }

  /**
   * @inheritdoc
   */
  public async signOut(): Promise<void> {
    const manager = this.getManager();
    await manager.clearStaleState();

    if (this.usePopUps()) {
      await manager.signoutPopup();
    } else {
      await manager.signoutRedirect();
    }
  }

  /**
   * @inheritdoc
   */
  public getUser(): Promise<cc.Core.IUser | null> {
    // If we have an ongoing query do not start a new one.
    if (this._userPromise !== null) {
      return this._userPromise;
    }

    this._userPromise = this.getUserCore()
      // Clear cache once the query finishes.
      .finally(() => {
        this._userPromise = null;
      });


    return this._userPromise;
  }

  /**
   * @inheritdoc
   */
  public addUserSessionChangedHandler(handler: cc.Core.UserSessionChangedHandler): void {
    this._userStateChangedHandlers.push(handler);
  }

  /**
   * @inheritdoc
   */
  public removeUserSessionChangedHandler(handler: cc.Core.UserSessionChangedHandler): void {
    const index = this._userStateChangedHandlers.indexOf(handler);
    if (index > -1) {
      this._userStateChangedHandlers.splice(index, 1);
    }
  }

  /**
   * Call all the registered handlers
   */
  private callUserStateChangedHandlers(subChanged: boolean): void {
    setTimeout(() => {
      for (const handler of this._userStateChangedHandlers) {
        handler(subChanged);
      }
    }, subChanged ? 1 : this.handlerDelay);
  }

  /**
   * Get the user manger.
   */
  protected getManager(): Oidc.UserManager {
    if (this._manager)
      return this._manager;

    throw new Error('Initialize the manager before using it.');
  }

  /**
   * @inheritdoc
   */
  public dispose(): Promise<void> {
    this._userStateChangedHandlers.splice(0, this._userStateChangedHandlers.length);

    if (this._manager) {
      this._manager.events.removeUserLoaded(this._stateChangedCallback);
      this._manager.events.removeUserSignedIn(this._stateChangedCallback);
      this._manager.events.removeUserSessionChanged(this._stateChangedCallback);
      this._manager.events.removeUserSignedOut(this._signOutCallback);
      this._manager.events.removeUserUnloaded(this._unloadCallback);
      this._manager.events.removeSilentRenewError(this._signOutCallback);
    }

    return Promise.resolve();
  }

  protected isChildWindow(): boolean {
    return this._window !== this._window.parent;
  }

  private usePopUps(): boolean {
    if (this.isChildWindow()) {
      return true;
    }
    return false;
  }

  protected getStateData(): Oidc.ExtraSigninRequestArgs {
    return {
      state: {
        location: this._window.location.href
      }
    };
  }

  protected async getUserCore(): Promise<cc.Core.IUser | null> {
    const user = await this.getUserSilent();
    if (user == null)
      return null;

    if (this._userContext && this._userContext.tag !== user.profile.sub) {
      this._userContext = null;
    }

    if (!this._userContext && user.access_token) {
      const userContextUrl = `${this._host.configuration.API_ROOT_URL!}${ServerHttpResources.LoggedUserContext}`;
      const authorizationHeader = { 'Authorization': `Bearer ${user.access_token}` };

      const userContextResponse = await cc.Utilities.sendAsync({ url: userContextUrl, method: 'GET', headers: authorizationHeader });
      this._userContext = getJsonIfSuccess(userContextResponse.request, this._logger);

      if (typeof this._userContext !== 'object' || !this._userContext) {
        this._logger.error(`Failed to retrieve userContext from "${userContextUrl}"`);
      }
    }

    const profile: cc.Core.IExtendedProfile<any> = {
      ...user.profile,
      extraInformation: this._userContext,
      functionalities: this._userContext ? this._userContext.functionalities?.map((m: any) => m.functionalityId) : [],
      culture: this._userContext?.userInformation?.specificCulture,
      country: this._userContext?.userInformation?.homeCountry?.isoA2,
      dateFormat: this._userContext?.userInformation?.dateFormat,
      timeFormat: this._userContext?.userInformation?.timeFormat,
      timeZone: this._userContext?.userInformation?.ianaTimeZone || this._userContext?.userInformation?.timeZoneId,
      unitSystem: this._userContext?.userInformation?.unitSystem,
      lengthFormat: this._userContext?.userInformation?.lengthFormat,
      weightFormat: this._userContext?.userInformation?.weightFormat,
      volumeFormat: this._userContext?.userInformation?.volumeFormat
    };

    return new cc.Core.User({
      accessToken: user.access_token,
      expiresAt: user.expires_at as number,
      state: user.state as string,
      profile: profile
    });
  }

  private async getUserSilent(): Promise<Oidc.User | null> {
    const manager = this.getManager();

    try {
      let user: Oidc.User | null = await this._getCoreUser(manager);
      if (user)
        return user;

      const status = await manager.querySessionStatus();
      if (!status || !status.sub)
        return null;

      await manager.removeUser();
      user = await manager.signinSilent();

      if (this.isValidStateData(manager, user)) {
        return user;
      }

    } catch (error: any) {
      /**
       * In some cases we are expecting an error:
       * - login_required: when we try to silently get the user without being logged-in.
       */
      const errorType = typeof error;
      let errorMessage: string;
      switch (errorType) {
        case 'object':
          errorMessage = error?.message;
          break;
        case 'string':
          errorMessage = error;
          break;
        default:
          errorMessage = error.toString();
      }

      if (!errorMessage) {
        errorMessage = 'Unknown error';
      }

      if (/login_required/gmi.test(errorMessage)) {
        this._logger.info(error);
      } else {
        this._logger.error(error);
      }
    }
    return null;
  }

  private async _getCoreUser(manager: Oidc.UserManager): Promise<Oidc.User | null> {
    const user = await manager.getUser();
    if (this.isValidStateData(manager, user)) {
      return user;
    }
    return null;
  }

  private isValidStateData(manager: Oidc.UserManager, user: Oidc.User | null): boolean {
    if (!user || user.expired) {
      return false;
    }

    const requiredScopes = (manager.settings.scope || '').split(' ');
    const userScopes = user.scopes || [];
    const missingScopes = requiredScopes.filter(scope => userScopes.indexOf(scope) === -1);

    if (missingScopes.length === 0) {
      return true;
    } else {
      this._logger.warn(`User is missing the following scopes "${missingScopes.join(' ')}".`);
      return false;
    }
  }

  /**
   * Get the current state store.
   * Returns the same instance all the time.
   *
   * @param window Current window reference.
   */
  public static GetStateStore(window: Window): Oidc.WebStorageStateStore {
    this.setLogger();

    if (!this.stateStore) {
      this.stateStore = new Oidc.WebStorageStateStore({
        store: window.localStorage
      });
    }
    return this.stateStore;
  }

  public static async Handle(window: Window): Promise<void> {
    this.setLogger();

    const getAction = (): string | null => {
      const q = new RegExp('[?&]__a=([^&#]*)').exec(window.location.search);
      return q && q[1];
    };

    try {
      const action = getAction();
      switch (action) {
        case 'silent_redirect':
          await this.HandleSilentRenewCallback(window);
          break;
        case 'redirect':
          await this.HandleSignInCallback(window, false);
          break;
        case 'popup_redirect':
          await this.HandleSignInCallback(window, true);
          break;
        case 'post_logout_redirect':
          await this.HandleSignOutCallback(window, false);
          break;
        case 'popup_post_logout_redirect':
          await this.HandleSignOutCallback(window, true);
          break;
        default:
        // NOOP
      }
    } catch (error: any) {
      Oidc.Logger.error(error);
    }
  }

  public static async HandleSilentRenewCallback(window: Window): Promise<void> {
    this.setLogger();

    const manager = this.getDefaultManager(window);
    try {
      await manager.signinCallback();
    } catch {
      // NOOP
    }
  }

  public static async HandleSignInCallback(window: Window, isPopup: boolean): Promise<void> {
    this.setLogger();

    const manager = this.getDefaultManager(window);

    let state: any = null;
    try {
      state = (await manager.signinCallback())?.state;
    } catch (error: any) {
      Oidc.Logger.error(error);
      state = null;
    }

    if (isPopup) {
      window.close();
    } else {
      const location = state?.location || cc.Utilities.getUrlOrigin(window.document, window.location.href);
      window.location.href = location;
    }
  }

  public static async HandleSignOutCallback(window: Window, isPopup: boolean): Promise<void> {
    this.setLogger();

    const manager = this.getDefaultManager(window);
    try {
      await manager.signoutCallback(undefined, !isPopup);
    } catch (error: any) {
      Oidc.Logger.error(error);
    }

    await this.clearUserMangerData(manager);

    if (isPopup && window.top === window) {
      window.close();
    }
  }

  /**
   * Get an Oidc.UserManager reference to handle callbacks.
   *
   * @param window Current window reference.
   */
  protected static getDefaultManager(window: Window): Oidc.UserManager {
    if (this.defaultManager) {
      return this.defaultManager;
    }

    const configuration = (window as any).MFEGlobals.configuration;

    this.defaultManager = this.buildOidcUserManager({
      ...GLOBAL_OIDC_SETTINGS,
      monitorSession: false, // we don't want to monitor the session in the callback
      client_id: '',
      redirect_uri: configuration[cc.Host.ConfigurationKeys.appRootPathAbsolute]!,
      authority: configuration[cc.Host.ConfigurationKeys.openIdAuthority]!,
      stateStore: this.GetStateStore(window),
      userStore: this.GetStateStore(window)
    });

    return this.defaultManager;
  }

  protected buildOidcManager(settings: Oidc.UserManagerSettings): Oidc.UserManager {
    return UserManager.createNewOidcUserManager(settings);
  }

  private static buildOidcUserManager(settings: Oidc.UserManagerSettings): Oidc.UserManager {
    return this.createNewOidcUserManager(settings);
  }

  protected static createNewOidcUserManager(settings: Oidc.UserManagerSettings): Oidc.UserManager {
    return new Oidc.UserManager(settings);
  }

  protected static async clearUserMangerData(manager?: Oidc.UserManager): Promise<void> {
    await Promise.all([
      this.clearStoreData(manager?.settings.stateStore),
      this.clearStoreData(manager?.settings.userStore)
    ]);
  }

  protected static async clearStoreData(stateStore: Oidc.StateStore | undefined): Promise<void> {
    if (!stateStore) {
      return;
    }

    try {
      const keys = await stateStore.getAllKeys();
      const proms: Promise<string | null>[] = [];
      for (const key of keys) {
        proms.push(stateStore.remove(key));
      }
      await Promise.all(proms);
    } catch (error: any) {
      Oidc.Logger.error(error);
    }
  }

  protected static setLogger(logger?: Oidc.ILogger): void {
    if (logger) {
      Oidc.Log.setLogger(logger);
      this.isLoggerSet = true;
    }

    if (this.isLoggerSet) {
      return;
    }

    Oidc.Log.setLogger(logger || console);
    this.isLoggerSet = true;
  }
}
